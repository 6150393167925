import React from 'react';
import SponsorsContainer from './SponsorsContainer';
import SponsorWrapper from './SponsorWrapper';
import Sponsor from './Sponsor';
import sponsor2 from './img/concello-pontevedra.svg';
import sponsor3 from './img/depo.png';
import sponsor4 from './img/froiz.jpg';
import sponsor7 from './img/gesmagal.png';
import sponsor9 from './img/lafuga.jpg';
import sponsor10 from './img/caetano.png';
import sponsor18 from './img/xsauce.png';
import sponsor24 from "./img/pizzeria_carlos.png";
import sponsor30 from "./img/nutridos.png";
//import sponsor31 from "./img/maglia.png";
import sponsor32 from "./img/mocaypascual.png";
import sponsor33 from "./img/dorita.png";
import sponsor34 from "./img/marin.png";
import sponsor35 from "./img/xeve.png";
import sponsor36 from "./img/ottobosley.png"


class Sponsors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [
        sponsor2,
        sponsor3,
        sponsor34,
        sponsor4,
        sponsor7,
        sponsor10,
        sponsor18,
        sponsor9,
        sponsor24,
        sponsor30,
        sponsor32,
        sponsor33,
        sponsor35,
        sponsor36
      ],
      currentIndex: 8,
      interval: null
    }
  }

  componentDidMount = () => {
    const interval = window.setInterval(() => {
      this.goToNextSponsor()
    }, 2000)
    this.setState({ interval })
  }

  goToNextSponsor = () => {
    // if (this.state.currentIndex === this.state.images.length - 1) {
    //   return this.setState({
    //     currentIndex: 0,
    //     translateValue: 0
    //   })
    // }

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.sponsorWidth()),
      images: [...prevState.images.slice(1), prevState.images[0]]
    }));
  }

  sponsorWidth = () => {
    return document.querySelector('.sponsor-wrapper').clientWidth
  }

  render() {
    return (
      <SponsorsContainer>
        {
          this.state.images.map((image, i) => (
            <SponsorWrapper key={i} className="sponsor-wrapper" style={{
              transform: `translateX(${this.state.translateValue}px)`
            }}>
              <Sponsor src={image} />
            </SponsorWrapper>
          ))
        }
      </SponsorsContainer>
    )
  }
}

export default Sponsors;
