import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import 'react-html5video/dist/styles.css';
import './EmbassadorPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class EmbassadorPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Embassador-hero"
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Embassador-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Embassador-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Embassador-title">Embajadores</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-8 offset-2">
                <article>
                  <h4>
                    <FormattedMessage id="embassador.title" defaultMessage="¿Quieres ser embajador de las pruebas GlobalDXT?" />
                  </h4>
                  <p>
                    <FormattedMessage id="embassador.text" defaultMessage="Estamos buscando personas activas, relacionadas con el deporte de las dos ruedas y que quieran vincularse a las pruebas que organizamos. Estas personas podrán asistir de forma gratuíta a nuestras pruebas, tener ropa de las mismas y otras contraprestaciones" />
                  </p>
                  <p>
                    <FormattedMessage id="embassador.text2" defaultMessage="¿Estás interesado o conoces a alguien que pueda estarlo? Envíanos a cimadevila@globaldxt.com tu currículum con los siguientes datos:" />
                  </p>
                  <ul>
                    <li>
                      <FormattedMessage id="embassador.data" defaultMessage="Nombre y apellidos" />
                    </li>
                    <li>
                      <FormattedMessage id="embassador.data1" defaultMessage="Edad" />
                    </li>
                    <li>
                      <FormattedMessage id="embassador.data2" defaultMessage="Teléfono de contacto" />
                    </li>
                    <li>
                      <FormattedMessage id="embassador.data3" defaultMessage="Email" />
                    </li>
                    <li>
                      <FormattedMessage id="embassador.data4" defaultMessage="Redes sociales en las que te mueves y su enlace" />
                    </li>
                    <li>
                      <FormattedMessage id="embassador.data5" defaultMessage="Número de seguidores en las redes sociales" />
                    </li>
                    <li>
                      <FormattedMessage id="embassador.data6" defaultMessage="Pruebas en las que has participado en 2018" />
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default EmbassadorPage;
