import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import 'react-html5video/dist/styles.css';
import './DescriptionPage.css';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import HeaderMobile from '../_components/HeaderMobile';

class DescriptionPage extends React.Component {
  render() {
    return (
      <div className='Embassador'>
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className='Embassador-hero'
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}></section>
        <section className='Embassador-banner'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <p>
                  <FormattedMessage
                    id='page.banner'
                    defaultMessage="APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='Embassador-info'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='Embassador-title'>Descripción</h3>
              </div>
            </div>
            <div className='row py-5'>
              <div className='col-8 offset-2'>
                <article className="text-justify">
                <p>LA ROAD PONTEVEDRA – CLÁSICA EVARISTO PORTELA es una de las pruebas cicloturistas referencia en el norte de España y Portugal. Esta prueba que nace en 2017, ha contado con multitud de personalidades del ciclismo como; Miguel Indurain, el más grande de todos los tiempos, Javier Gómez Noya, Óscar Pereiro, Moisés Dueñas o Ezequiel Mosquera. Todos ellos, junto con el resto de los participantes, han convertido a La Road Pontevedra en una cicloturista de referencia.</p>
                <p>En la prueba los participantes disfrutarán de carreteras secundarias con encanto, siendo este año muy especial al verse renovado el recorrido en el que se pretende hacer de esta prueba si cabe todavía más CLÁSICA, donde todo tipo de ciclistas podrán disfrutar de un evento que cuida hasta el más mínimo detalle.</p>
                <p>Los participantes irán comandados por toda la logística de organización (motos, coches, ambulancias) dando la máxima seguridad a lo largo del recorrido. Los primeros 40 km, la marcha será neutralizada, llevando a todo el pelotón dentro de la cápsula de seguridad realizada por la Guardia Civil de tráfico. A partir del km 40, cada participante PODRÁ LLEVAR SU PROPIA VELICIDAD hasta el final de la prueba. Aquellos participantes que sean rebasados por el vehiculo de organización de cierre de capsula, debe saber que TIENE QUE CUMPLIR LAS NORNAS DE TRAFICO, aunque la organización sigue manteniendo el operativo de seguridad en cruces para facilitar el disfutre de la prueba.</p>
                <p>Una cicloturista con encanto en el que todo ciclista puede ver satisfechas sus necesidades al contar con DOS TRAMOS CRONOMETRADOS para que cada uno pueda exprimirse al máximo. Se hará una clasificación SOLAMENTE con la suma de los tiempos de los tramos cronometrados. </p>
                <p>La prueba dispone de una única distancia que será de 112 km con 1850 metros de desnivel positivo. </p>
                <p>El objetivo es que todos los aficionados a la bicicleta de carretera puedan disfrutar de una ruta increíble y superen el reto de finalizarla. Además, esta prueba quiere rendir homenaje a D. Evaristo Portela, que tanto ha dado al ciclismo, tanto como deportista, como en su función de director técnico.</p>
                <p>Un montaje de gran prueba, una salida espectacular, un recorrido único, grandes avituallamientos, gran seguridad durante toda la prueba, zona de exposiciones, zona de participantes, difusión en medios audiovisuales, revistas especializadas del sector… es todo lo que puede ofrecer esta espectacular prueba.</p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
         <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ''
            ) : (
              <section className='inscription-fixed-bar'>
                <Button
                  className='inscription-fixed'
                  href='#'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default DescriptionPage;
