import React from 'react';
import Slide from './Slide.jsx';
import RightArrow from './RightArrow.jsx';
import LeftArrow from './LeftArrow.jsx';
import Countdown from '../Countdown';
import slide1 from './slide1.jpg';
import slide2 from './slide2.jpg';
import slide3 from './slide3.jpg';
import bgOverlay from './diagonalBlack.svg';
import'./Slider.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";

class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [slide1, slide2, slide3],
      currentIndex: 0, 
      interval: null
    }
  }

  componentDidMount = () => {
    const interval = window.setInterval(() => {
      this.goToNextSlide()
    }, 3000)
    this.setState({ interval })
  }

  goToPrevSlide = () => {

  }

  goToNextSlide = () => {
    if (this.state.currentIndex === this.state.images.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      })
    }

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.slideWidth())

    }));
  }

  slideWidth = () => {
    return document.querySelector('.slide').clientWidth
  }

  render() {
    return (
      <div className="slider">
        <div className="slider-wrapper"
          style={{
            transform: `translateX(${this.state.translateValue}px)`
          }}>
            {
              this.state.images.map((image, i) => (
                <Slide key={i} image={image} />
              ))
            }
        </div>

        <div className="slider-overlay"
          style={{
            backgroundImage: `url(${bgOverlay})`
          }}>
        </div>
        <h3 className="slider-caption-2">11 de mayo de 2025</h3>
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Countdown date="2025-05-11T00:00:00" />
            ) : (
              ''
            )}
        </Media>
        <LeftArrow 
          goToPrevSlide={this.goToPrevSlide}
          />
        <RightArrow 
          goToNextSlide={this.goToNextSlide}
          />
      </div>
    );
  }
}

export default Slider;
