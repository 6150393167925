import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import 'react-html5video/dist/styles.css';
import './PreguntasPage.css';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import HeaderMobile from '../_components/HeaderMobile';
import Accordion from 'react-bootstrap/Accordion';

const faqs = [
  {
    title: '¿Cuántos kilómetros tiene la prueba?',
    text: <p>La prueba consta de una única distancia que serán 112 km</p>,
  },
  {
    title: '¿Qué acumulado positivo tiene la prueba?',
    text: (
      <p>
        La prueba tendrá un acumulado positivo de 1850 metros.
      </p>
    ),
  },
  {
    title: '¿Está el recorrido flechado?',
    text: (
      <p>
        Si, el recorrido está marcado en su totalidad. El marcaje se realiza con flechas y señales que permitirán un correcto transcurso de la prueba.
      </p>
    ),
  },
  {
    title: '¿Qué pasa si no llego al punto de control?',
    text: (
      <p>
        Automáticamente quedará fuera de carrera, en este momento todo participante se considera en excursión personal y por lo tanto fuera de la cobertura ofrecida por la prueba, tanto en recorrido, como en cruces, avituallamientos u otro servicio ofrecido por la misma.
      </p>
    ),
  },
  {
    title: '¿Si no puedo ir a la prueba, puedo ceder mi inscripción?',
    text: (
      <p>
        Si, antes de la fecha establecida en el reglamento de la prueba.
      </p>
    ),
  },
  {
    title: '¿El seguro de cancelación me cubre cualquier tipo de circunstancia?',
    text: (
      <p>
        <strong>NO</strong>. El seguro cubre muchas situaciones, pero no todas, para tener más información revisa el apartado de “Cancelaciones” en el reglamento.
      </p>
    ),
  },
  {
    title: '¿Cuándo debo recoger la ropa que he encargado?',
    text: (
      <p>
       <strong>TODA LA ROPA SE RECOGERÁ EL DÍA DEL EVENTO EN LA ZONA DE “TIENDA”</strong>. No se realizarán envíos en ningún momento
      </p>
    ),
  },
  {
    title: '¿Me podrían enviar la ropa una vez pasada la fecha del evento?',
    text: (
      <p><strong>NO.</strong> En caso de no recoger la ropa el día del evento, el participante se quedará sin dicha ropa. <strong>NO SE REALIZARÁN ENVÍOS</strong></p>
    ),
  },
  {
    title:
      '¿La prueba es abierta al tráfico?',
    text: (
      <p>
        No, La guardia civil de tráfico establecerá una <strong>CAPSULA DE SEGURIDAD</strong>. Aquellos participantes que sean superados por el vehículo fin de prueba, deben saber que pasan a estar con TRÁFICO ABIERTO, aunque seguirán teniendo personal de control en los cruces y los servicios de la prueba (avituallamiento, zona de llegada…).
      </p>
    ),
  },
  {
    title: '¿Es obligatorio llevar móvil en la prueba?',
    text: (
      <p>
        Sí, para que la organización pueda ponerse en contacto si surge algún imprevisto o hay algún problema. Importante que tenga batería suficiente.
      </p>
    ),
  },
  {
    title: '¿Qué hago si no decido seguir en la prueba?',
    text: (
      <p>
       Fundamental que de aviso a la organización lo antes posible para poder tener controlados a todos los participantes y velar por su seguridad.
      </p>
    ),
  },
  {
    title: '¿Cuándo puedo retirar el dorsal?',
    text: (
      <p>
        -	La recogia de dorsales se realizará  en el Pazo de Congresos en Pontevedra u otro lugar a determinar por la organización. Sería el viernes 9 de Mayo de 17:00 a 20:30,  el sábado 10 de Mayo de 10 a 13:00 horas y de 16:00 a 20:00 horas o el Domingo 11 de 7:00 a 8:30 horas.
      </p>
    ),
  },
  {
    title: '¿Puede retirar el dorsal algún compañero por mí?',
    text: (
      <p>
        Sí, siempre que tenga DNI y los papeles que se deben tener cubiertos para la prueba.
      </p>
    ),
  },
];

class PreguntasPage extends React.Component {
  render() {
    return (
      <div className='Embassador'>
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className='Embassador-hero'
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}></section>
        <section className='Embassador-banner'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <p>
                  <FormattedMessage
                    id='rules.banner'
                    defaultMessage='APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE'
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='Embassador-info'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='Embassador-title'>Preguntas frecuentes</h3>
              </div>
            </div>
            <div className='row py-5'>
              <div className='col-8 offset-2'>
                <Accordion defaultActiveKey='0'>
                  {faqs.map((faq, index) => {
                    const { title, text } = faq;
                    return (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{title}</Accordion.Header>
                        <Accordion.Body>{text}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ''
            ) : (
              <section className='inscription-fixed-bar'>
                <Button
                  className='inscription-fixed'
                  href='#'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default PreguntasPage;
