import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import styled from 'styled-components';
import ImageHero from './image1.jpg';
import './SponsorsPage.css';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import HeaderMobile from '../_components/HeaderMobile';
import sponsor2 from './img/concello-pontevedra.svg';
import sponsor3 from './img/depo.png';
import sponsor4 from './img/froiz.jpg';
import sponsor7 from './img/gesmagal.png';
import sponsor9 from './img/lafuga.jpg';
import sponsor10 from './img/caetano.png';
import sponsor18 from './img/xsauce.png';
import sponsor24 from "./img/pizzeria_carlos.png";
import sponsor30 from "./img/nutridos.png";
//import sponsor31 from "./img/maglia.png";
import sponsor32 from "./img/mocaypascual.png";
import sponsor33 from "./img/dorita.png";
import sponsor34 from "./img/marin.png";
import sponsor35 from "./img/xeve.png";
import sponsor36 from "./img/ottobosley.png"

const level1 = [
  {
    name: 'Concello Pontevedra',
    to: 'https://www.pontevedra.gal',
    image: sponsor2,
  },
];

const _level1 = [
  {
    name: 'Deputación Pontevedra',
    to: 'https://www.depo.gal',
    image: sponsor3,
  },
];

const level2 = [
  {
    name: 'Concello de Marín',
    to: 'https://www.depo.gal',
    image: sponsor34,
  },
  {
    name: 'Froiz',
    to: 'https://www.froiz.com',
    image: sponsor4,
  },
  {
    name: 'Nutridos',
    to: "https://nutridos.com/es/",
    image: sponsor30,
  },
  {
    name: 'Caetano Renault',
    to: 'https://www.caetanoformulagalicia.es/',
    image: sponsor10,
  },
];

const level3 = [
  {
    name: 'La Fuga',
    to: 'https://www.lafugacycling.com/',
    image: sponsor9
  },
  {
    name: "Ottobosley",
    to: "https://ottobosley.com/index.php?",
    image: sponsor36
  },
  {
    name: 'Gesmagal',
    to: 'https://www.gesmagal.com',
    image: sponsor7,
  },
  {
    name: "Pizzeria Carlos",
    to: "https://pizzeriascarlos.es/#/home",
    image: sponsor24
  },
  {
    name: 'Pascual Mocay',
    to: "https://www.pascualprofesional.com/mocay/",
    image: sponsor32,
  },
  {
    name: "Dorita",
    to: "https://igmontes.com/",
    image: sponsor33
  },
  {
    name: 'Xsauce',
    to: 'https://x-sauce.com/es/',
    image: sponsor18,
  },
  {
    name: 'Xeve',
    to: '',
    image: sponsor35,
  },
]


class SponsorsPage extends React.Component {
  render() {
    return (
      <div className='Sponsors'>
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className='Sponsors-hero'
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}></section>
        <section className='Sponsors-banner'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <p>
                  <FormattedMessage
                    id='level2.banner'
                    defaultMessage="APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='Sponsors-info'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='Sponsors-title'>Sponsors</h3>
              </div>
            </div>
            <div className='row justify-content-center '>
              {level1.map((sponsor) => {
                const { name, image, to } = sponsor;
                return (
                  <div className='col-12 col-sm-4 col-lg-4 mt-3 '>
                    <a
                      href={to}
                      className='sponsor-wrapper p-4'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
            <div className='row justify-content-center mb-5'>
              {_level1.map((sponsor) => {
                const { name, image, to } = sponsor;
                return (
                  <div className='col-12 col-sm-4 col-lg-4 mb-5'>
                    <a
                      href={to}
                      className='sponsor-wrapper p-4'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
            <div className='row justify-content-center mb-5'>
              {level2.map((sponsor) => {
                const { name, image, to } = sponsor;
                return (
                  <div className='col-12 col-sm-3 col-lg-3 mb-5'>
                    <a
                      href={to}
                      className='sponsor-wrapper second-sponsor p-5'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
            <div className='row justify-content-center mb-5'>
              {level3.map((sponsor) => {
                const { name, image, to } = sponsor;
                return (
                  <div className='col-12 col-sm-3 col-lg-3 mt-3 mb-5'>
                    <a
                      href={to}
                      className='sponsor-wrapper third-sponsor'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default SponsorsPage;
