import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.png";
import "react-html5video/dist/styles.css";
import "./ModalidadesPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

class ModalidadesPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Embassador-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        ></section>
        <section className="Embassador-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage
                    id="page.banner"
                    defaultMessage="APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Embassador-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Embassador-title">Modalidades</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-8 offset-2">
                <article className="text-justify">
                  <p>
                    Se establecen diferentes modalidades con el afán de generar la máxima diversión en los tramos cronometrados. Solo en estos tramos las modalidades serán efectivas sin cobrar importancia en el resto del recorrido.
                  </p>
                  <ul>
                    <li><strong>ÉLITE – SUB-23 MASCULINO:</strong> aquellos participantes que son poseedores de licencia federativa élite. En caso de acogerse a la licencia de día, aquellos participantes que tienen entre 18 y 29 años el día en el que se celebra la prueba.</li>
                    <li><strong>ÉLITE – SUB-23 FEMENINO:</strong> aquellas participantes que son poseedoras de la licencia federativa élite. En caso de acogerse a la licencia de día, aquellas participantes que tienen entre 18 y 29 años el día en el que se celebra la prueba.</li>
                    <li><strong>MÁSTER 30 MASCULINO:</strong> aquellos participantes que son poseedores de la licencia federativa máster 30. En caso de acogerse a la licencia de día, aquellos participantes que tienen entre 30 y 39 años el día en el que se celebra la prueba.</li>
                    <li><strong>MÁSTER 40 MASCULINO:</strong> aquellos participantes que son poseedores de la licencia federativa máster 40. En caso de acogerse a la licencia de día, aquellos participantes que tienen entre los 40 y 49 años el día en el que se celebra la prueba.</li>
                    <li><strong>MÁSTER 50 MASCULINO:</strong> aquellos participantes que son poseedores de la licencia federativa máster 50. En caso de acogerse a la licencia de día, aquellos participantes que tienen entre 50 y 59 años el día en el que se celebra la prueba.</li>
                    <li><strong>GRANMASTER MASCULINO:</strong> aquellos participantes que tienes 60 o más años en día en el que se celebra la prueba.</li>
                    <li><strong>MÁSTER FEMENINO:</strong> aquellas participantes con cualquier licencia master. En caso de acogerse a la licencia de día, las participantes que tengan 30 o más años.</li>
                  </ul>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/es/services/inscription/la-road-pontevedra---ldquoclsica-evaristo-portelardquo-2024"
                  
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default ModalidadesPage;
